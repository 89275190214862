.table-fixed tbody {
  display: block;
  max-height: 15em;
  overflow: auto;
}

.table-fixed thead,
.table-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-fixed th,
.table-fixed td {
  padding: 0.25em;
}

.table-fixed thead {
  width: calc(100%);
}

.table-fixed table {
  width: 400px;
}
