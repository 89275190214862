.Visualisation {
  display: flex;
  /*min-height: 80vh;*/
}

.filter-visualization {
  flex: 1;
}

.filter-visualization ul {
  font-size: small;
  padding: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
}

.visualization-table {
  width: 100%;
}

.visualization-table td {
  white-space: nowrap;
}

.chart-cell {
  vertical-align: top;
}

.filter-data {
  vertical-align: top;
  text-align: left;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.chart-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
