.table-fixed tbody {
  display: block;
  max-height: 15em;
  overflow: auto;
}

.table-fixed thead,
.table-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-fixed th,
.table-fixed td {
  padding: 0.25em;
  margin-left: auto;
  margin-right: auto;
}

.table-fixed thead {
  width: calc(100%);
}

.table-fixed table {
  width: 500px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;
  justify-content: center;
  overflow: visible;
  position: relative;
}

.table-cell {
  border: 1px solid #000;
  width: 200px;
}

.select_button {
  z-index: 19999;
  color: blue;
  position: relative;
}

.table-fixed{
  overflow-x : auto;
}

