body {
  background-color: #f5f5f5;
}

h2 {
  font-size: 1.8rem;
}

.App {
  text-align: center;
  margin: 0em;
  padding: 0 1em;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
}

.Main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Container-fill {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
