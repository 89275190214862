#study {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#study table {
  border: 1px solid #ccc;
}

#study table tbody tr th {
  background-color: #ddd;
  text-align: right;
}

#study table tbody tr td {
  text-align: left;
}

.study-details {
  text-align: initial;
}

.feature-modal .modal-body {
  max-height: 85vh;
  overflow-y: scroll;
}

.feature-description {
  font-size: small;
  font-family: monospace;
}

.min-width-510 {
  min-width: 510px;
}
