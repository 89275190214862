.FeatureConfig-tree
  > .MuiTreeItem-root
  > .MuiTreeItem-content
  > .MuiTypography-root {
  text-align: center;
}

.ROIList {
  max-height: 200px;
  overflow-y: auto;
}

.ROIList-Item {
  flex: 0 0 30%;
  font-size: smaller;
}
