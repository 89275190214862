.Patients-list {
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.Splitting-choice {
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.Splitting-choice .alert {
  flex-grow: 1;
  align-items: center;
  display: flex;
}
