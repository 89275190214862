.features-wrapper {
  display: flex;
}

.collections-list {
  flex-basis: 200px;
  flex-shrink: 0;
  font-size: smaller;
}

.collection-container {
  background: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 1em;
}

.features-table {
}

.feature-tabs {
  /*overflow-x: hidden;*/
  flex-grow: 1;
  width: calc(100% - 250px);
}

.tab-content {
  border: 1px solid #dee2e6;
  border-top: 0;
  padding: 0;
  background-color: white;
}

.data-filters {
  display: flex;
}

.data-filter {
  flex: 1;
}

.data-filter .btn {
  border: 0;
}

.data-filter .btn.btn-primary.active {
  /*background-color: #75b8ff;*/
}

.nav-link {
  cursor: pointer;
}

.info-container {
  display: flex;
}

.info-container div {
  flex: 1;
  margin: 1em 0.5em;
  text-align: center;
}
