.whole-table-container {
  /*background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;*/
  margin: 0em 0;
}

.table-container {
  overflow-x: auto;
}

.table-container table {
  border-spacing: 0;
  font-size: smaller;
}

.table-container table thead tr:first-child th,
.table-container table thead tr:nth-child(2) th {
  text-align: left;
}

.table-container table tbody {
  overflow-x: scroll;
}

.table-container table tr :last-child td {
  border-bottom: 0;
}

.table-container table th,
.table-container table td {
  margin: 0;
  padding: 0.25rem;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.pagination {
  align-items: center;
  justify-content: flex-start;
}
