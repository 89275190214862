.form-container > form {
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  margin-bottom: 1em;
}

.data-points td {
  vertical-align: inherit;
}

.data-label input {
  width: 120px;
  margin: auto;
}

.data-label input[type='text'] {
  text-align: center;
}

.narrow-table {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.model-entry {
  /*border-radius: 10px;
  margin: 1em;
  padding: 1em;*/
  text-align: center;
}

.model-details-container {
  display: flex;
  justify-content: center;
}

.model-details-table {
  margin: 1em;
  width: 500px;
  text-align: left;
}

.model-details-table tbody tr td:nth-child(odd) {
  font-weight: bold;
}

.metrics-table,
.confusion-matrix {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.model-row:hover {
  background-color: #eeeeee;
}

.model-row:hover .model-row-icon {
  color: #666;
}

.algorithm-details h5 {
  margin: 0.5em;
}
